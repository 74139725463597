<template>
  <div class="level">
    <div class="level-item">
        <div class="card" v-if="! postSuccess">
        <header class="card-header has-background-primary">
            <h4 class="card-header-title is-centered title is-4 has-text-white">User Profile</h4>
        </header>
        <div class="card-content"  v-if="showForm">  
            <form action="" @submit.prevent="handleSubmit">
                
                <div class="container">

                <b-field label="Email" label-for="email">
                    <b-input
                    name="email" 
                    id="email" 
                    type="email"
                    placeholder="Email Address"
                    icon-pack="fas" 
                    icon="envelope" 
                    v-model=user.email
                    disabled=true 
                    validation-message="Please enter a vaild email address">
                    </b-input>
                </b-field>

                <b-field label="Full Name" label-for="name">
                    <b-input
                    name="name" 
                    id="name" 
                    type="name"
                    placeholder="Enter your full name"
                    icon-pack="fas" 
                    icon="user" 
                    v-model="user.name"
                    minlength=3
                    aria-required
                    validation-message="Please enter a valid name. Requires at least 3 characters">
                    </b-input>
                </b-field>
                    
                <b-field label="Phone" label-for="phoneNumber">
                    <b-input
                    name="phoneNumber" 
                    id="phoneNumber" 
                    type="tel"
                    placeholder="Phone Number"
                    icon-pack="fas" 
                    icon="mobile" 
                    v-model="user.phoneNumber"
                    minlength=10
                    validation-message="Please enter a valid phone number">
                    </b-input>
                </b-field>

                <b-field label="Current Password" label-for="curPassword" message="Enter only if you want to reset password">
                    <b-input
                    name="curPassword" 
                    id="curPassword" 
                    type="password"
                    placeholder="Enter Current Password"
                    icon-pack="fas" 
                    icon="lock" 
                    v-model="curPassword"
                    validation-message="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
                    </b-input>
                </b-field>
               <b-tooltip class="is-default" position="is-right" :always="isToolTipActive" animated multilined>
                    <div class="has-text-weight-bold">New Password: &nbsp;<i class="fas fa-info-circle has-text-info"></i></div>
                    <template v-slot:content>
                        <div class="panel">
                            <ul>
                                <li>
                                    Minimum Length 8
                                </li>
                                <li>
                                    At least 1 Number
                                </li>
                                <li>
                                    At least 1 Uppercase Letter
                                </li>
                                <li>
                                    At least 1 Lowercase Letter
                                </li>                        
                            </ul> 
                        </div>
                    </template>
                </b-tooltip> 
                <b-field label="" label-for="newPassword" message="Enter only if you want to reset password">
                    <b-input
                    name="newPassword" 
                    id="newPassword" 
                    type="password"
                    ref="PasswordInput"
                    placeholder="Enter New Password"
                    icon-pack="fas" 
                    icon="lock" 
                    v-model="newPassword" 
                    minlength=8
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    validation-message="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
                    </b-input>
                </b-field>

                <b-field label="Repeat Password" label-for="repeatPassword" message="Enter only if you want to reset password">
                    <b-input
                    name="repeatPassword" 
                    id="repeatPassword" 
                    type="password"
                    placeholder="Repeat the New Password"
                    icon-pack="fas" 
                    icon="lock" 
                    v-model="repeatPassword"
                    minlength=8
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    password-reveal
                    validation-message="Please repeat the password you entered above. Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters">
                    </b-input>
                </b-field>
                    <br>
                    <div class="has-text-red" v-if="! repeatPasswordMatched">Password not match</div>
                    
                <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
                <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
                </div>
            </form>
        </div>
        </div>  
   </div>
  </div>
</template>

<script>

import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import ErrorMessage from '../../components/app/ErrorMessage'
import UserStore from "../../store/UserStore"
import router from "../../router"

export default {
    
    data() {
        return {
            user: {},
            errors: [],
            showForm: true,
            curPassword: '',
            newPassword: '',
            repeatPassword: '',
            postSuccess: false,
            heroColor: 'is-primary',
            messagetype: 'is-warning',
            isToolTipActive: true
        }
    },

    components: {
        ErrorMessage
    },

    methods: {

        handleSubmit() {

            if ( this.formComplete ) {

                var url = process.env.VUE_APP_API_ROOT_URL + '/users/profile/update'
                var md5password = ""
                var md5curPassword = ""
                if (this.newPassword.length > 6) {
                    var crypto = require('crypto')
                    md5curPassword = crypto.createHash('md5').update(this.curPassword).digest("hex")
                    md5password = crypto.createHash('md5').update(this.newPassword).digest("hex")
                }
                axios.post(url,
                    {
                        name: this.user.name,
                        email: this.user.email,
                        phoneNumber: this.user.phoneNumber,
                        id: this.user.id,
                        curPassword: md5curPassword,
                        newPassword: md5password
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                 
                    })

                .then( (response) => {

                    this.email = ""

                    if (response.status === 200) {
                        this.postSuccess = true
                        this.$buefy.notification.open({
                                message: 'User Information Updated',
                                type: 'is-success',
                                duration: 6000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    }
                    if (md5password) {
                        UserStore.commit('userLogout')
                    } else {
                        router.push({ path: 'home' })
                    }
                    
                })
                    
                .catch( (err) => {
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.email=""
                    this.$buefy.notification.open({
                            message: err.response.data + ' Update Failed. Please try again!',
                            type: 'is-danger',
                            duration: 6000,
                            'has-icon': true,
                            'icon-pack': 'fas',
                            icon: 'exclamation-circle'
                        })
                })  
            } else {
                console.log('Not posted')
            }
        },

        // getUser() {
        //     this.user = UserStore.getters.getUser
        //     this.user.password = ''
        // },

        isEmailValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },

        addError(err) {
            if (this.errors.indexOf(err) == -1) {
                this.errors.push(err)
            }
        },

        removeError(err) {
            var index = this.errors.indexOf(err)
            if (index > -1) {
                this.errors.splice(index)
            }
        }
    },

    computed: {
        formComplete() {
            if (! this.isEmailValid(this.user.email)) {
                return false
            }
            
            if (! this.user.name ) {
                this.addError('Name is required')
                return false
            } else {
                this.removeError('Name is required')
            }

            if (! this.user.phoneNumber ) {
                this.addError('Phone is required')
                return false
            } else {
                this.removeError('Phone is required')
            }

            if (this.newPassword) {
                var message='Password must be atleast 8 characters long'
                if (this.newPassword.length < 8) {
                    this.addError(message)
                    return false
                } else {
                    this.removeError(message)
                    message='Repeated password does not match'
                    if (! this.repeatPasswordMatched) {
                        this.addError(message)
                        return false
                    } else {
                        this.removeError(message)
                        if (! this.curPassword) {
                            message='Current Password Required'
                            this.addError(message)
                            return false
                        } else {
                            this.removeError(message)
                        }
                    }
                }
            } 

            return true
        },

        repeatPasswordMatched() {
            if (this.newPassword == this.repeatPassword) {
                return true
            }
            return false
        },

        
    },

    mounted() {
        this.user = this.getUser()
        setTimeout(function() { 
            this.isToolTipActive = false
        }.bind(this), 4000)
    }
}
</script>
